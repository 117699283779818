import React from 'react';
import SearchResults from '../components/search-results/search-results';
import { Location } from '@reach/router';

const SokPage = () => {
  return (
    <Location>
      {({ location }) => (
        <SearchResults query={new URLSearchParams(location.search)} />
      )}
    </Location>
  );
};

export default SokPage;
