import React from 'react';
import { Typography, Box } from '@mui/material';
import { connect } from 'react-redux';
import { useGetArticleList, useGetEcomCommon } from '../../hooks';
import { RootState } from '../../store';
import { ArticleCardList } from '../article-card-list';
import { FavouriteArticlePage } from '../article-card-list/favourite-article-page.type';

const mapStateToProps = (state: RootState) => {
  return { favouritesFromStore: state.shop.favourites };
};

interface SearchResultsProps {
  query: URLSearchParams;
  favouritesFromStore: string[];
}

const SearchResults = ({ query, favouritesFromStore }: SearchResultsProps) => {
  const ecomCommon = useGetEcomCommon();
  const articleList = useGetArticleList();

  const searchTerm = query.get('query') ?? '';
  const searchTermIsNumeric = !isNaN(Number(searchTerm));

  const hitList = articleList.filter((article) => {
    if (searchTermIsNumeric) {
      //Search for articleNumber should alway produce exact hits.
      return article.articleNumber === searchTerm;
    } else {
      //String search should match against title and only return hits where title includes searchTerm
      return article.title.toLowerCase().includes(searchTerm.toLowerCase());
    }
  });

  if (!searchTermIsNumeric) {
    hitList.sort((hitA, hitB) => {
      //Hoist hits that have searchTerm appear earlier in tiltle
      if (hitA.title.indexOf(searchTerm) < hitB.title.indexOf(searchTerm)) {
        return -1;
      } else if (
        hitA.title.indexOf(searchTerm) > hitB.title.indexOf(searchTerm)
      ) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  const articlesMappedToFavourites: FavouriteArticlePage[] = hitList.map(
    (article) => {
      return {
        ...article,
        isFavourite: favouritesFromStore.includes(article.articleNumber),
      };
    },
  );

  // TODO: get hardcoded values from Contentful
  return (
    <>
      <Typography variant="h1" component="h1" sx={{ my: 4 }}>
        Sökresultat
      </Typography>
      <Box sx={{ mb: 3, mt: 3 }}>
        Din sökning <strong>{searchTerm}</strong> gav {hitList.length} träffar
      </Box>
      <ArticleCardList
        articleList={articlesMappedToFavourites}
        ecomCommon={ecomCommon}
      />
    </>
  );
};

export default connect(mapStateToProps)(SearchResults);
